export default [
	{
		label: "Home",
		target: "home"
	}, {
		label: "Leistungen",
		target: "services"
	}, {
		label: "Kontakt",
		target: "contact"
	}
];