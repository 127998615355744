import React, { Component } from "react";
import PropTypes from "prop-types";

import { HamburgerMinus } from "react-animated-burgers";
import { Nav as NavComp } from "reusable-components";
import { scrollToElement } from "reusable-components/helper";
import { ArrowRight } from "reusable-components/ui/buttons";

import { LogoFull } from "../../brand/logo";
import Styles from "./index.module.scss";
import NavElements from "./nav-elements";


export default class Nav extends Component {
	static propTypes = {
		navElements: PropTypes.array,
		disableCTA: PropTypes.bool
	};

	static defaultProps = {
		navElements: NavElements,
		disableCTA: false
	};

	render () {
		return (
			<NavComp
				scrollOffset={ -75 }

				RootContainerProps={ {
					className: Styles.rootContainer,
				} }

				ContainerProps={ {
					className: Styles.navContainer,
				} }

				LogoComp={ () => <LogoFull className={ Styles.logo } /> }
				LogoProps={ {
					className: Styles.logo
				} }

				NavItemsContainerProps={ {
					className: Styles.navItemsContainer,
				} }
				NavItems={ this.props.navElements }
				NavItemProps={ {
					className: Styles.navItem,
				} }

				CTAButtonComp={ this.props.disableCTA ? undefined : CTAButton }

				MobileNavComp={ MobileNav }
				mobileBreakpoint={ 1215 }
			/>
		);
	}
}

class MobileNav extends Component {
	state = {
		isActive: false
	};

	_globalListener = null;

	toggleActive () {
		this.setState({
			...this.state,
			isActive: !this.state.isActive
		});
	}

	handleGlobalMouseDown (event) {
		if (event.target.parentElement.parentElement.classList.contains(Styles.mobileNav)) {
			return false;
		}

		if (this.state.isActive) {
			this.toggleActive();
		}
	}

	componentDidMount () {
		this._globalListener = this.handleGlobalMouseDown.bind(this);
		window.addEventListener("mousedown", this._globalListener);
	}
	
	scrollTo (target) {
		scrollToElement(`#${ target }`);
	}

	render () {
		return (
			<div
				onClick={ this.toggleActive.bind(this) }
				className={ Styles.mobileNav }>

				<HamburgerMinus
					className={ Styles.burger }
					isActive={ this.state.isActive }
				/>

				{ this.state.isActive &&
					<div className={ Styles.menu }>
						{ NavElements.map(({ label, target }) =>
							<div
								key={ label }
								onClick={ this.scrollTo.bind(this, target) }
								className={ Styles.navItem }>
								{ label }
							</div>
						) }

						<ArrowRight
							renderAs={ "a" }
							href={ "/blog/" }
							key={ "cta-button" }
							className={ Styles.ctaButton }>
							Blog
						</ArrowRight>
					</div>
				}
			</div>
		);
	}
}

const CTAButton = () =>
	<li>
		<div className={ Styles.separator } />

		<ArrowRight
			className={ Styles.ctaButton }
			renderAs={ "a" }
			href={ "/blog/" }>
			Blog
		</ArrowRight>
	</li>;